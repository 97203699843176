var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "default-layout-container" },
    [
      _c("div", { staticClass: "h-full w-full grid" }, [
        _c(
          "div",
          [
            _c("p", { staticClass: "text-grey" }, [
              _vm._v(
                " You can fund your dollar card by using money from your wallet. "
              ),
            ]),
            _c("CurrencyInput", {
              staticClass: "mt-5",
              attrs: {
                placeholder: "Amount in Naira",
                width: "w-full",
                showCurrency: true,
              },
              model: {
                value: _vm.amountInNaira,
                callback: function ($$v) {
                  _vm.amountInNaira = $$v
                },
                expression: "amountInNaira",
              },
            }),
            _c(
              "div",
              { staticClass: "flex justify-between text-sm mb-4 mt-1" },
              [
                _c(
                  "p",
                  {
                    staticClass: "text-sm",
                    class:
                      _vm.amountInNaira > _vm.instaPayWallet
                        ? "text-brandRed"
                        : "text-grey",
                  },
                  [
                    _vm._v(" Wallet amount = "),
                    _vm.instaPayWallet
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatMoney")(_vm.instaPayWallet))
                          ),
                        ])
                      : _c("span", [_vm._v(_vm._s(_vm._f("formatMoney")(0)))]),
                  ]
                ),
              ]
            ),
            _c("CurrencyInput", {
              staticClass: "mt-8",
              attrs: {
                placeholder: "Dollar equivalent",
                width: "w-full",
                symbol: "$",
                showCurrency: true,
              },
              model: {
                value: _vm.amountInDollars,
                callback: function ($$v) {
                  _vm.amountInDollars = $$v
                },
                expression: "amountInDollars",
              },
            }),
            _c("p", { staticClass: "text-sm mt-1 text-blue" }, [
              _vm._v(
                " At the rate of $1 = " +
                  _vm._s(_vm._f("formatMoney")(_vm.rate)) +
                  " "
              ),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "flex items-end mt-4" }, [
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c("div", { staticClass: "spam-notice mb-4 mt-10" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "20",
                      height: "20",
                      viewBox: "0 0 20 20",
                      fill: "none",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                        fill: "#0EA2F5",
                      },
                    }),
                  ]
                ),
                _vm._m(0),
              ]),
              _c("Button", {
                staticClass: "w-full",
                attrs: { text: "Fund account" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "Modal",
        {
          attrs: {
            display: _vm.showFundAccount,
            title: "Add money to account",
          },
          on: { close: _vm.closeFundWallet },
        },
        [
          _c("FundAccount", {
            on: {
              completed: _vm.completed,
              close: function ($event) {
                _vm.showFundAccount = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-sm text-grey w-full" }, [
      _vm._v(" Withdrawal guide: "),
      _c("br"),
      _vm._v(" A fee of $1 is attached to every withdrawal "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }